import { combineReducers } from 'redux';

import basicState, { basicInitialState } from './basic';
import keyState, { keyInitialState } from './key';
import listState, { getListInitialState } from './list';

export const getTimeseriesInitialState =  () => ({ 
  basicState: basicInitialState,
  keyState: keyInitialState,
  listState: getListInitialState()
})

export default combineReducers({ 
  basicState, 
  keyState,
  listState
})