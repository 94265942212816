import { combineReducers } from 'redux';

import basicState, { getBasicInitialState } from './basic';

export const getCurvesInitialState =  () => ({ 
  basicState: getBasicInitialState()
})

export default combineReducers({ 
  basicState
})